export const LOG_IN_REQUEST='LOG_IN_REQUEST'
export const LOG_IN_SUCCESS='LOG_IN_SUCCESS'
export const LOG_IN_FAILURE='LOG_IN_FAILURE'

export const LOG_OUT_REQUEST='LOG_OUT_REQUEST'
export const LOG_OUT_SUCCESS='LOG_OUT_SUCCESS'
export const LOG_OUT_FAILURE='LOG_OUT_FAILURE'

export const USER_CHECK_REQUEST='USER_CHECK_REQUEST'
export const USER_CHECK_SUCCESS='USER_CHECK_SUCCESS'
export const USER_CHECK_FAILURE='USER_CHECK_FAILURE'


